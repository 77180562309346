<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!stockMovement">
      <span class="loader"></span>
    </span>
    <stock-movement-form
      v-if="stockMovement"
      :loading="loading"
      :stockMovementData="stockMovement"
      :formErrors="formErrors"
      @stockMovementSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import StockMovementForm from "../partials/StockMovementForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { StockMovementForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    stockMovementId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      stockMovement: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("stockMovements/get", this.stockMovementId);
        this.stockMovement =
          this.$store.getters["stockMovements/stockMovement"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(stockMovement) {
      this.loading = true;
      const stockMovementData = cloneDeep(stockMovement);

      try {
        await this.$store.dispatch("stockMovements/update", stockMovementData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("PRODUCTS.PRODUCT_UPDATED"),
        });
        const stockMovement =
          this.$store.getters["stockMovements/stockMovement"];
        this.$emit("onViewStockMovement", stockMovement, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
