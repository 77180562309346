var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-title"},[_c('i',{staticClass:"fa-regular fa-arrow-trend-up"}),_c('h1',[_vm._v(_vm._s(_vm.$t("COMMON.STOCK_MOVEMENTS")))])]),_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"page-wrapper-header",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("STOCK_MOVEMENTS.STOCK_MOVEMENTS_LIST"))+" ")])]),_c('div',{staticClass:"col-6 text-right"},[(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_STOCK_MOVEMENTS))?_c('base-button',{staticClass:"elite-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.openStockMovementCreateModal}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-plus-circle"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.$t("STOCK_MOVEMENTS.ADD_STOCK_MOVEMENT")))])]):_vm._e()],1)])]),_c('stock-movement-list-table',{key:_vm.renderKey * 100,on:{"onViewStockMovement":_vm.openStockMovementViewModal,"onEditStockMovement":_vm.openStockMovementEditModal,"onDeleteStockMovement":_vm.deleteStockMovement}}),(_vm.isViewStockMovementModalOpened)?_c('div',{key:_vm.renderKey * 200,staticClass:"resizable-wrapper",class:[_vm.isViewStockMovementModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('STOCK_MOVEMENT'),expression:"'STOCK_MOVEMENT'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":function($event){return _vm.closeStockMovementModal(true, true)}}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("STOCK_MOVEMENTS.VIEW_STOCK_MOVEMENT"))+" ")])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[(
                _vm.openStockMovementLoaded
                  ? _vm.openStockMovement.items.length > 0 &&
                    _vm.openStockMovement.status === _vm.STATUS_DRAFT
                  : false
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.validateStockMovement(_vm.openStockMovement)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fal fa-check"})])]):_vm._e(),(_vm.openStockMovement.status === _vm.STATUS_VALIDATED)?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm"},on:{"click":function($event){return _vm.cancelStockMovement(_vm.openStockMovement)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fal fa-times"})])]):_vm._e(),(_vm.openStockMovement)?_c('notification-subscription',{attrs:{"objectType":'stockMovements',"objectId":_vm.openStockMovement.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}}):_vm._e(),_c('base-dropdown',{attrs:{"title-classes":"btn dropdown-button","menu-on-right":"","has-toggle":false}},[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-ellipsis-v"})]),(_vm.openStockMovement.status === _vm.STATUS_DRAFT)?_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.openStockMovementEditModal(_vm.openStockMovement)}}},[_c('i',{staticClass:"fal fa-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.EDIT")))])]):_vm._e(),(_vm.openStockMovement.status === _vm.STATUS_DRAFT)?_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.deleteStockMovement(_vm.openStockMovement)}}},[_c('i',{staticClass:"fal fa-trash-alt"}),_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.DELETE")))])]):_vm._e()],2),_c('button',{staticClass:"close",on:{"click":function($event){return _vm.closeStockMovementModal(true)}}},[_c('i',{staticClass:"fal fa-times"})])],1)]),_c('div',{staticClass:"resizable-wrapper-content-body"},[(_vm.openStockMovement)?_c('view-stock-movement-component',{attrs:{"stockMovementId":_vm.openStockMovement.id},on:{"stockMovementLoaded":(stockMovement) => {
                _vm.openStockMovement = stockMovement;
                _vm.openStockMovementLoaded = true;
              }}}):_vm._e()],1)])]):_vm._e(),(_vm.isEditStockMovementModalOpened)?_c('div',{key:_vm.renderKey * 300,staticClass:"resizable-wrapper",class:[_vm.isEditStockMovementModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('STOCK_MOVEMENT'),expression:"'STOCK_MOVEMENT'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeStockMovementModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("STOCK_MOVEMENTS.EDIT_STOCK_MOVEMENT"))+" ")])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[_c('button',{staticClass:"close",on:{"click":function($event){return _vm.openStockMovementViewModal(_vm.openStockMovement)}}},[_c('i',{staticClass:"fal fa-times"})])])]),_c('div',{staticClass:"resizable-wrapper-content-body"},[(_vm.openStockMovement)?_c('edit-stock-movement-component',{attrs:{"stockMovementId":_vm.openStockMovement.id},on:{"onViewStockMovement":_vm.openStockMovementViewModal}}):_vm._e()],1)])]):_vm._e(),(_vm.isAddStockMovementModalOpened)?_c('div',{key:_vm.renderKey * 400,staticClass:"resizable-wrapper",class:[_vm.isAddStockMovementModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('STOCK_MOVEMENT'),expression:"'STOCK_MOVEMENT'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeStockMovementModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("STOCK_MOVEMENTS.ADD_STOCK_MOVEMENT"))+" ")]),_c('button',{staticClass:"close",on:{"click":_vm.closeStockMovementModal}},[_c('i',{staticClass:"fal fa-times"})])]),_c('div',{staticClass:"resizable-wrapper-content-body"},[_c('add-stock-movement-component',{on:{"onViewStockMovement":_vm.openStockMovementViewModal}})],1)])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }