<template>
  <div class="container-fluid">
    <div class="page-title">
      <i class="fa-regular fa-arrow-trend-up"></i>
      <h1>{{ $t("COMMON.STOCK_MOVEMENTS") }}</h1>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("STOCK_MOVEMENTS.STOCK_MOVEMENTS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openStockMovementCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_STOCK_MOVEMENTS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("STOCK_MOVEMENTS.ADD_STOCK_MOVEMENT")
              }}</span>
            </base-button>
            <!--<notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_STOCK_MOVEMENTS)"
              :objectType="'stockMovements'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />-->
          </div>
        </div>
      </div>

      <stock-movement-list-table
        @onViewStockMovement="openStockMovementViewModal"
        @onEditStockMovement="openStockMovementEditModal"
        @onDeleteStockMovement="deleteStockMovement"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewStockMovementModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewStockMovementModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'STOCK_MOVEMENT'"
          @onCloseModal="closeStockMovementModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("STOCK_MOVEMENTS.VIEW_STOCK_MOVEMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="validateStockMovement(openStockMovement)"
                v-if="
                  openStockMovementLoaded
                    ? openStockMovement.items.length > 0 &&
                      openStockMovement.status === STATUS_DRAFT
                    : false
                "
              >
                <span class="btn-inner--icon">
                  <i class="fal fa-check"></i>
                </span>
              </base-button>

              <base-button
                type="danger"
                icon
                size="sm"
                @click="cancelStockMovement(openStockMovement)"
                v-if="openStockMovement.status === STATUS_VALIDATED"
              >
                <span class="btn-inner--icon">
                  <i class="fal fa-times"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openStockMovement"
                :objectType="'stockMovements'"
                :objectId="openStockMovement.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openStockMovementEditModal(openStockMovement)"
                  v-if="openStockMovement.status === STATUS_DRAFT"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="edit"
                  @click="deleteStockMovement(openStockMovement)"
                  v-if="openStockMovement.status === STATUS_DRAFT"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeStockMovementModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-stock-movement-component
              v-if="openStockMovement"
              :stockMovementId="openStockMovement.id"
              @stockMovementLoaded="
                (stockMovement) => {
                  openStockMovement = stockMovement;
                  openStockMovementLoaded = true;
                }
              "
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditStockMovementModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditStockMovementModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'STOCK_MOVEMENT'"
          @onCloseModal="closeStockMovementModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("STOCK_MOVEMENTS.EDIT_STOCK_MOVEMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openStockMovementViewModal(openStockMovement)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-stock-movement-component
              v-if="openStockMovement"
              :stockMovementId="openStockMovement.id"
              @onViewStockMovement="openStockMovementViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddStockMovementModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddStockMovementModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'STOCK_MOVEMENT'"
          @onCloseModal="closeStockMovementModal"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("STOCK_MOVEMENTS.ADD_STOCK_MOVEMENT") }}
            </h1>
            <button class="close" @click="closeStockMovementModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-stock-movement-component
              @onViewStockMovement="openStockMovementViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import {
  STATUS_DRAFT,
  STATUS_CANCELED,
  STATUS_VALIDATED,
} from "@/constants/stockMovements";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import StockMovementListTable from "./partials/StockMovementListTable.vue";
import EditStockMovementComponent from "./components/EditStockMovementComponent.vue";
import AddStockMovementComponent from "./components/AddStockMovementComponent.vue";
import ViewStockMovementComponent from "./components/ViewStockMovementComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    StockMovementListTable,
    NotificationSubscription,
    EditStockMovementComponent,
    AddStockMovementComponent,
    ViewStockMovementComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewStockMovementModalOpened = false;
    let isEditStockMovementModalOpened = false;
    let isAddStockMovementModalOpened = false;
    let openStockMovement = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewStockMovementModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditStockMovementModalOpened = true;
      }
      openStockMovement = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddStockMovementModalOpened = true;
    }
    return {
      isViewStockMovementModalOpened: isViewStockMovementModalOpened,
      isEditStockMovementModalOpened: isEditStockMovementModalOpened,
      isAddStockMovementModalOpened: isAddStockMovementModalOpened,
      openStockMovement: openStockMovement,
      openStockMovementLoaded: false,
      renderKey: 1,
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_CANCELED: STATUS_CANCELED,
      STATUS_VALIDATED: STATUS_VALIDATED,
    };
  },

  methods: {
    openStockMovementCreateModal() {
      this.closeStockMovementModal();
      this.isAddStockMovementModalOpened = true;

      this.$router.replace({
        name: "List StockMovements",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },

    async openStockMovementViewModal(stockMovement, reRender = false) {
      this.closeStockMovementModal();
      this.openStockMovement = stockMovement;
      this.isViewStockMovementModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List StockMovements",
        query: { id: this.openStockMovement.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    openStockMovementEditModal(stockMovement) {
      this.closeStockMovementModal();
      this.openStockMovement = stockMovement;
      this.isEditStockMovementModalOpened = true;

      this.$router.replace({
        name: "List StockMovements",
        query: { id: this.openStockMovement.id, action: QUERY_ACTIONS_EDIT },
      });
    },

    closeStockMovementModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddStockMovementModalOpened = false;
      this.isViewStockMovementModalOpened = false;
      this.isEditStockMovementModalOpened = false;
      this.openStockMovementLoaded = false;
      this.openStockMovement = null;

      if (
        this.$route.name !== "List StockMovements" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List StockMovements",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async validateStockMovement(stockMovement) {
      try {
        await this.$store.dispatch("stockMovements/validate", stockMovement.id);
        this.openStockMovement =
          this.$store.getters["stockMovements/stockMovement"];
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("STOCK_MOVEMENTS.STOCK_MOVEMENT_VALIDATED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelStockMovement(stockMovement) {
      const confirmation = await swal.fire({
        title: this.$t("STOCK_MOVEMENTS.CANCEL_STOCK_MOVEMENT_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isConfirmed) {
        return;
      }

      try {
        await this.$store.dispatch("stockMovements/cancel", stockMovement.id);
        this.openStockMovement =
          this.$store.getters["stockMovements/stockMovement"];
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("STOCK_MOVEMENTS.STOCK_MOVEMENT_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteStockMovement(stockMovement) {
      const confirmation = await swal.fire({
        title: this.$t("STOCK_MOVEMENTS.DELETE_THIS_STOCK_MOVEMENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch(
            "stockMovements/destroy",
            stockMovement.id
          );
          this.renderKey++;
          this.closeStockMovementModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("STOCK_MOVEMENTS.STOCK_MOVEMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
